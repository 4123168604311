import React from 'react';
import PropTypes from 'prop-types';

import LinkOut from './linkOut';

import styles from './componentStyles/header.module.css';

const Header = ({ siteTitle }) => (
  <header className={styles.header}>
    <div className={styles.left}>
      <div className={styles.inner}>
        {/* <LinkOut
          className={styles.underlineLink}
          link='https://cellprofiling.org/'
        >
          Lundberg Lab
        </LinkOut>
        <LinkOut
          className={styles.noUnderlineLink}
          link='http://idekerlab.ucsd.edu'
        >
          {' - '}
        </LinkOut>
        <LinkOut
          className={styles.underlineLink}
          link='http://idekerlab.ucsd.edu'
        >
          Ideker Lab
        </LinkOut> */}
      </div>
    </div>
    <div className={styles.center}>
      <LinkOut className={styles.underlineLink} link='https://hms.harvard.edu'>
        Harvard Medical School
      </LinkOut>
      <LinkOut
        className={styles.noUnderlineLink}
        link='https://hms.harvard.edu'
      >
        {' - '}
      </LinkOut>
      <LinkOut
        className={styles.underlineLink}
        link='https://gygi.med.harvard.edu'
      >
        Gygi
      </LinkOut>
      <LinkOut
        className={styles.noUnderlineLink}
        link='https://hms.harvard.edu'
      >
        {' & '}
      </LinkOut>
      <LinkOut
        className={styles.underlineLink}
        link='https://harper.hms.harvard.edu'
      >
        Harper Lab
      </LinkOut>
    </div>
    <div className={styles.right}>
      <div className={styles.inner}>
        <LinkOut className={styles.underlineLink} link='https://www.kth.se/en'>
          KTH
        </LinkOut>
        <LinkOut
          className={styles.noUnderlineLink}
          link='https://www.scilifelab.se'
        >
          {' - '}
        </LinkOut>
        <LinkOut
          className={styles.underlineLink}
          link='https://cellprofiling.org'
        >
          Lundberg Lab
        </LinkOut>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
