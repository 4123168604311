import React from 'react';
import PropTypes from 'prop-types';

import ccmi from '../images/ccmi.png';
import schmidt from '../images/schmidt_futures.png'
import styles from './componentStyles/logoHeader.module.css';

const LogoHeader = ({ siteTitle }) => (
  <header className={styles.header}>
       <span className={styles.content}>
      <a
        className={styles.noUnderlineLink}
        href='/'
        //target='_blank'
        rel='noopener noreferrer'
      >
        {' Home '}
      </a>
      </span>
    {/* <a href='https://www.schmidtfutures.com/' target='_blank' rel='noopener noreferrer'>
      <img src={schmidt} alt='Schmidt Futures Logo' className={styles.logo} />
    </a>
    <span className={styles.content}>

      <a
        className={styles.underlineLink}
        href='https://idekerlab.ucsd.edu'
        target='_blank'
        rel='noopener noreferrer'
      >
        Ideker Lab
      </a>

      <a
        className={styles.noUnderlineLink}
        href='https://idekerlab.ucsd.edu'
        target='_blank'
        rel='noopener noreferrer'
      >
        {' - '}
      </a>

      <a
        className={styles.underlineLink}
        href='https://cellprofiling.org/'
        target='_blank'
        rel='noopener noreferrer'
      >
        Lundberg Lab
      </a>

    </span> */}
  </header>
);

LogoHeader.propTypes = {
  siteTitle: PropTypes.string,
};

LogoHeader.defaultProps = {
  siteTitle: ``,
};

export default LogoHeader;
